import { allLanguages, getCtfPageMessages } from "../../../../apps/base/src/lib/contentful"

type PagesContentTypeQueryValues = Parameters<typeof getCtfPageMessages>[0]
export type PagesContentTypeQueryKeys = keyof typeof PagesContentTypeQuery
export type PagesContentTypeQuery = Record<PagesContentTypeQueryKeys, PagesContentTypeQueryValues>
export type GetCtfPageMessagesResponse = Awaited<ReturnType<typeof getCtfPageMessages>>
export type ArrayedKeys<T> = T extends (infer U)[] ? U : never
export type ArrayedResponseType<T extends PagesContentTypeQueryKeys[]> = {
  [K in ArrayedKeys<T> & keyof ContentfulPageResponse]: ContentfulPageResponse[K]
}
export type ResultTypeByKeys<T> = T extends PagesContentTypeQueryKeys
  ? { [P in T & keyof ContentfulPageResponse]: ContentfulPageResponse[P] }
  : T extends PagesContentTypeQueryKeys[]
    ? ArrayedResponseType<T>
    : never

// Add a page here if you want to use it in the app
export const PagesContentTypeQuery = {
  homepage_page: { contentType: "homepage_page" } as PagesContentTypeQueryValues, // copy this line if you need intellisense
  calculator: { contentType: "calculator_page" },
  common: { contentType: "common" },
  our_story_page: { contentType: "our_story_page" },
  partners_page: { contentType: "partners_page" },
  app_layout: { contentType: "app_layout" },

  // Inner pages
  faq: { contentType: "faq_page" },
  terms_of_use_page: { templateKey: "terms_of_use_page", contentType: "terms_of_use_page" },
  trademarks_page: { templateKey: "trademarks_page", contentType: "terms_of_use_page" },
  privacy_page: { templateKey: "privacy_page", contentType: "terms_of_use_page" },
  disclosure_page: { templateKey: "disclosure_page", contentType: "terms_of_use_page" },
  data_sec_page: { templateKey: "data_sec_page", contentType: "terms_of_use_page" },
  accessibility_page: { templateKey: "accessibility_page", contentType: "terms_of_use_page" },
  managed_account_page: { templateKey: "managed_account_page", contentType: "terms_of_use_page" },
  subscribe_page: { contentType: "subscribe_page" },
  pension_subscribe_page: { templateKey: "pension_subscribe_page", contentType: "subscribe_page" },

  // Profile Section
  proflie_next_step: { templateKey: "profile_page.next_step", contentType: "section" },
} as const

interface ContentfulPageResponse {
  homepage_page: HomePageResponse
  our_story_page: OurStoryResponse
  app_layout: AppLayoutResponse
  terms_of_use_page: DynamicTabsPageResponse
  trademarks_page: DynamicTabsPageResponse
  privacy_page: DynamicTabsPageResponse
  disclosure_page: DynamicTabsPageResponse
  data_sec_page: DynamicTabsPageResponse
  accessibility_page: DynamicTabsPageResponse
  managed_account_page: DynamicTabsPageResponse
  partners_page: PartnersPageResponse
}

export type AllContentTypes =
  | "app_layout"
  | "calculator_page"
  | "common"
  | "homepage_page"
  | "our_story_page"
  | "partners_page"
  | "profile_page"
  /** Footer pages */
  | "accessibility_page"
  | "data_sec_page"
  | "disclosure_page"
  | "privacy_page"
  | "terms_of_use_page"
  | "faq_page"
  | "trademarks_page"
  /** Products pages */
  | "funds_page"
  | "stocks_page"
  | "savings_page"
  | "consult_page"
  | "insurance_page"
  | "investments_page"
  | "profile_page.next_step"
  | "subscribe_page"
  | "pension_subscribe_page"

interface UseContentfulPageOptions {
  mergeLocaleMessages?: boolean
}

const DEFAULT_OPTIONS: UseContentfulPageOptions = {
  mergeLocaleMessages: false,
}

export async function useContentfulPage<T extends AllContentTypes | AllContentTypes[]>(
  keys: T,
  options?: UseContentfulPageOptions
) {
  const pagesKeys = toArray(keys)
  const i18n = useI18n()

  options = { ...DEFAULT_OPTIONS, ...options }

  const { data } = await useFetch("/api/cms", {
    query: { keys: pagesKeys, locale: i18n.locale.value },
    keepalive: true,
  })

  if (options?.mergeLocaleMessages) {
    mergeLocaleMessage(data.value)
  }

  const dataByLang = computed(() => {
    const locale = i18n.locale.value as keyof GetCtfPageMessagesResponse

    return data.value?.[locale] as ResultTypeByKeys<T>
  })

  function mergeLocaleMessage(messages: typeof allLanguages): Record<keyof typeof allLanguages, any> {
    if ("en" in messages) i18n.mergeLocaleMessage("en", messages.en)
    if ("he" in messages) i18n.mergeLocaleMessage("he", messages.he)

    return messages
  }

  return { dataByLang }
}
